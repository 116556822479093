<template>
    <footer id="footer">
        <div class="footer__box socialNetworks">
            <a :href="item.link" 
                v-for="item in socialNetworks" 
                :key="item.link" 
                target="_blank"
                class="footer__link-social">
                <i :class="item.class"></i>
            </a>
            <!-- <transition name="fade-footer"> -->
            <div v-show="addWaveCocao" class="bg-wave-cocao">
                <img src="../assets/wave_cocao.png" alt="">
            </div>
            <!-- </transition> -->
            <transition name="fade-footer">
                <div v-if="addContentFooter" class="footer__copyright">{{ copyright }}</div>
            </transition>
            <transition name="fade-footer">
                <div v-if="showTextFoundFooter" class="distinction-text" v-html="showNumberDifferences"></div>
            </transition>
        </div>
        <transition name="fade-footer">
            <div v-if="addContentFooter" class="footer__box">
                <a :href="developer.link" class="footer__link" target="_black">
                    <img :src="developer.image" alt="">
                    <span v-html="developer.text"></span>
                </a>
                <router-link class="button footer__button button_white" tag="button" :to="`/${this.langContent}/`" v-on:click.native="goToPage()">
                    <i class="far fa-angle-up"></i>
                </router-link>
            </div>
        </transition>
        <transition name="fade-footer" >
            <!-- addButtonQuest -->
            <div v-show="addButtonQuest" class="footer__box">
                <p class="number-quest" v-html="numberQuestAppText"></p>
                <button class="button button_white" @click="skipQuest()" v-html="gameBtNext"></button>
            </div>
        </transition>
        <div v-if="removeButtonScroll" class="footer__buttonScroll">
            <span class="footer__buttonScroll-text">{{ textButtonNextScroll }}</span>
            <button class="button button-down button_white" @click="nexPage">
                <i class="far fa-angle-down"></i>
            </button>
        </div>
    </footer>
</template>
<script>
import axios from 'axios'
import VueAxios from 'vue-axios'
import { HTTP, Routes } from '../API'
export default {
    props:{
        addContentFooter:{type: Boolean,required: false},
        addButtonQuest:{type: Boolean,required: false},
        showTextFoundFooter:{type: Boolean,required: false},
        showNumberDifferences:{type: String},
        removeButtonScroll:{type: Boolean},
        textButtonNextScroll:{type: String},
        numberQuestAppText:{type: String},
        langContent:{type: String, default: 'ru'},
        addWaveCocao:{type: Boolean}
    },
    data(){
        return{
            srollPageVal: 'slide-fadeTop',
            nextPageValue: '',
            idGame: '',
            percent:'',
            questNumber: 0,
            questNumberText: '',
            gameOverId0: false,
            gameOverId1: false,
            copyright: null,
            socialNetworks:[],
            developer:[],
            gameBtNext: null,
        }
    },
    methods: {
        goToPage(){
            this.$emit('scrollPage',this.srollPageVal)
        },
        idPageFooter(value){
            let routes = this.$router.options.routes
            let idx = routes.findIndex(item => item.path === this.$route.path)
            if(value === -1){
                value = 1
            }
            value ++

            this.nextPageValue = String(routes[value].path)
        },
        nexPage(){
            this.$router.push({path:`${this.nextPageValue}`})
            this.srollPageVal = 'slide-fadeTop'        
            this.$emit('scrollPage',this.srollPageVal)
        },
        skipQuest(){ 
            this.questNumber = Number(localStorage.idGame) +1
            if(isNaN(this.questNumber) === true){
               this.questNumber = 0
            } 
            if(this.questNumber !== 4){
                this.questNumber ++
                
                if(this.questNumber === 4){
                    this.questNumber = 3
                }
                let routes = this.$router.options.routes
                let pathFinishedGame = routes[1].children[this.questNumber].path
                this.$router.push({ path: `/${this.langContent}/game/${pathFinishedGame}` })
                
                if(String(pathFinishedGame) === 'win'){
                     this.questNumber = 0
                }
                // Записать id игры
                this.idGame = this.$route.meta.id - 1
                // Записать процент
                if(localStorage.percent === undefined){
                    this.percent = 0
                }else if(Number(localStorage.percent) > 0){
                    this.percent = Number(localStorage.percent) + 0
                }
            }
        },
        getContent(){
            HTTP.get(Routes.footer)
                .then(  response => {
                    this.socialNetworks = response.data.footer.soc_network
                    this.copyright = response.data.footer.copyright
                    this.developer = response.data.footer.developer
                })
                .catch( error => {
                console.log(error);
            });
            HTTP.get(Routes.games_options)
                .then(  response => {
                    this.gameBtNext = response.data.games_options.btn_next
                })
                .catch( error => {
                console.log(error);
            });
        }
        
    },
    created(){
        this.$parent.$on('idPage', this.idPageFooter)
    },
    watch:{
        idGame(newName) {
            // console.log('newName: ', newName);
            localStorage.idGame = newName
        },
        percent(newName) {
            localStorage.percent = newName
        },
        gameOverId0(newName){
            localStorage.gameOverId0 = newName
        },
        gameOverId1(newName){
            localStorage.gameOverId1 = newName
        },

    },
    mounted(){
        this.getContent();
    },
}
</script>