import axios from 'axios';
export const testLoc = 'https://macchoco.jet5.ru';
export const locOrig = 'https://macchoco.ru';

export const HTTP = axios.create({
    baseURL: locOrig,
    timeout: 30000
  });

let pathLang = 'ru';
function langContent(){
  const path = window.location.pathname.split('/');
  pathLang = path[1];
};

langContent();
  export const Routes = {
    home: `/includes/api/getData.php?type_data=main&lang=${pathLang}`,
    cooking: `/includes/api/getData.php?type_data=cooking&lang=${pathLang}`,
    recipes: `/includes/api/getData.php?type_data=recipes&lang=${pathLang}`,
    news: `/includes/api/getData.php?type_data=news&lang=${pathLang}`,
    about: `/includes/api/getData.php?type_data=about&lang=${pathLang}`,
    lang: `/includes/api/getData.php?type_data=lang&lang=${pathLang}`,
    footer: `/includes/api/getData.php?type_data=footer&lang=${pathLang}`,
    games_options: `/includes/api/getData.php?type_data=games_options&lang=${pathLang}`,
    menu: `/includes/api/getData.php?type_data=menu&lang=${pathLang}`,
    shops: `/includes/api/getData.php?type_data=shops&lang=${pathLang}`,
    game_find_differences: `/includes/api/getData.php?type_data=game_find_differences&lang=${pathLang}`,
    game_assemble_picture: `/includes/api/getData.php?type_data=game_assemble_picture&lang=${pathLang}`,
    game_find_way: `/includes/api/getData.php?type_data=game_find_way&lang=${pathLang}`,
    result_quests: `/includes/api/getData.php?type_data=result_quests&lang=${pathLang}`,
    popUpCompleted: `/includes/api/getData.php?type_data=popUpCompleted&lang=${pathLang}`,
    err_page: `/includes/api/getData.php?type_data=err_page&lang=${pathLang}`,
  };
