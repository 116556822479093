<template>
    <div class="game-content">
        <PopUpCompleted :popUpShow="popUpShow" :langContent="langContent"/>
        <h2 class="game-content__title" v-html="gameTitle"></h2>
        <div id="game-find">
            <!-- <img src="../assets/pin.png" class="cursor" :style="styleCursor" alt=""> -->
            <div class="game-find__box">
                <div class="secondCursor" :style="styleCursor"></div>
                <div v-for="(item,index) in amountPoints" :key="index" :class="'point-position-'+ index" class="point" :data-point="index" @click="pointSelection"></div>    
                <img src="../assets/games/distinction/1.png" alt="">
            </div>
            <div class="game-find__box">
                <div class="secondCursor" :style="styleCursor"></div>
                <div v-for="(item,index) in amountPoints" :key="index" :class="'point-position-'+ index" class="point" :data-point="index" @click="pointSelection"></div>    
                <img src="../assets/games/distinction/2.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import VueAxios from 'vue-axios'
import PopUpCompleted from '@/components/PopUpCompleted.vue'
import { HTTP, Routes } from '../API'

export default {
    components:{
        PopUpCompleted
    },
    props: {
            langContent: String
    },
    data(){
        return{
            activeCursor: false,
            idGame: '',
            percent:'',
            // winText: '',
            gameOverId0: false,
            text: 'Подкрепись MacChoco и попробуй еще раз',
            x: 0,
            y: 0,
            number: '',
            numberDistinction: 0,
            allMatches: 5,
            popUpShow: false,
            amountPoints: 5,
            showTextFound: false,
            styleCursor:{
                top: 0,
                left: 0,
            },

            gameTitle: null,
            searchText: '',
            numberQuest: '',
        }
    },
    methods:{
        mousemoveCursor(e){
            let target = e.target
            target.parentElement.classList.add('notCursol')
            let box = document.getElementsByClassName('game-find__box')
            if(this.activeCursor === false){
                this.activeCursor = true
                for (let index = 0; index < box.length; index++) {
                    if(box[index].classList.contains('notCursol') === false){
                        box[index].firstChild.classList.add('secondCursor_active')
                    }
                }
            }
            // Игнорировать поинты 
            if(target.classList.contains('point') === true){
                target = target.parentElement
            }
            let targetCoords = target.getBoundingClientRect();
            let xCoord = e.clientX - targetCoords.left.toFixed()
            let yCoord = e.clientY - targetCoords.top.toFixed()
            this.styleCursor.top = yCoord - 20 + 'px'
            this.styleCursor.left = xCoord - 30 + 'px'
        },
        mouseoutCursor(){
            let box = document.getElementsByClassName('game-find__box')
            this.activeCursor = false
            for (let index = 0; index < box.length; index++) {
                box[index].classList.remove('notCursol')
                box[index].firstChild.classList.remove('secondCursor_active')   
            }
        },
        pointSelection(e){
            let point = e.target
            // Добавить актив для поинта
            if(point.classList.contains('selected') === false){
                let nameClass = point.getAttribute("class")
                let poinCollection = document.getElementsByClassName(nameClass)
                for (let index = 0; index < poinCollection.length; index++) {
                     poinCollection[index].classList.add('selected')
                }
                this.numberDistinction ++
                // Окончание игры
                const search = '#NUMBER#';
                let replaceWith = String(''+this.numberDistinction+'')
                this.number = this.searchText.split(search).join(replaceWith)
                this.$emit('numberDifferences', this.number)

                if(this.numberDistinction === this.allMatches){
                    setTimeout(()=>{
                        // Показать popUp
                        this.popUpShow = true
                        // Записать id игры
                        this.idGame = this.$route.meta.id
                        this.percent = '33'
                        // Игра была закончина
                        localStorage.gameOverId0 = true
                    },800)
                } 
            }
        }
    },
    mounted(){
        let cursorBox = document.getElementsByClassName('game-find__box')
        for (let index = 0; index < cursorBox.length; index++) {
            cursorBox[index].addEventListener('mousemove',this.mousemoveCursor)
            cursorBox[index].addEventListener('mouseout',this.mouseoutCursor)
        }
        // Была ли закончина игра 
        if(Boolean(localStorage.gameOverId0) === true) {
            // показать popUp
            this.popUpShow = true
            // Активировать все точки
            let point = document.getElementsByClassName('point')
            for (let index = 0; index < point.length; index++) {
                point[index].classList.add('selected')                
            }
        }

        HTTP.get(Routes.game_find_differences)
                .then(  response => {
                this.gameTitle = response.data.game_find_differences.title
            })
            .catch( error => {
            console.log(error);
        });

        HTTP.get(Routes.games_options)
                .then(  response => {
                this.searchText = response.data.games_options.distinction
                this.numberQuest = response.data.games_options.quest
                setTimeout(() => {
                    const search = '#NUMBER#';
                    let replaceWith = '1'
                    this.numberQuest = this.numberQuest.split(search).join(replaceWith)
                    this.$emit('numberQuestGame',this.numberQuest)
                    if(this.searchText.length !== 0){
                        const search = '#NUMBER#';
                        let replaceWith = '0'
                        this.number = this.searchText.split(search).join(replaceWith)
                        this.$emit('numberDifferences', this.number)
                        }
                }, 0)
            })
            .catch( error => {
                console.log(error);
            });

    },
    watch:{
        idGame(newName) {
            localStorage.idGame = newName
        },
        percent(newName) {
            localStorage.percent = newName
        },
        gameOverId0(newName){
            localStorage.gameOverId0 = newName
        }
    },
    beforeCreate(){
        this.showTextFound = true
        this.$emit('addTextFoundFooter',this.showTextFound)
    },
    destroyed(){
        // Обнулить счетчик (Нашел)
        if(this.searchText.length !== 0){
            const search = '#NUMBER#';
            let replaceWith = '0'
            this.number = this.searchText.split(search).join(replaceWith)
            this.$emit('numberDifferences', this.number)
        }
        this.showQuestButton = false
        this.$emit('addQuestButtonFooter',this.showQuestButton)
        this.showTextFound = false
        this.$emit('addTextFoundFooter',this.showTextFound)
    }
}
</script>

