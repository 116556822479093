import '@/css/fontawesome.min.css'
import '@/css/regular.min.css'

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/index'
// Подключаем наши стили
import './css/style.less'

import axios from 'axios'
import VueAxios from 'vue-axios'



Vue.use(VueAxios, axios)



// window.axios = require('axios');
Vue.use(VueRouter)

new Vue({
  el: '#app',
  render: h => h(App),
  router
})
 