<template>
    <div class="background-PopUpCompleted" :class="{popUpShow:popUpShow}">
        <div class="PopUpCompleted__content" :class="{popUpcontentShow:popUpShow}">
            <div class="PopUpCompleted__title" v-html="popUpTitle"></div>
            <button class="button PopUpCompleted__button"  @click="next">{{popUpButton}}</button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import { HTTP, Routes } from '../API'
    export default {
        props:{
            popUpShow:{type: Boolean,required: false},
            langContent:{type: String},
        },
        data(){
            return{
                popUpTitle: null,
                popUpButton: null
            }
        },
        methods:{
            next(){
                // Перейти на следующий квест
                if(localStorage.idGame){
                    let routes = this.$router.options.routes
                    let number = Number(localStorage.idGame)
                    number ++ 
                    let pathFinishedGame = routes[1].children[number].path
                    this.$router.push({ path: `/${this.langContent}/game/${pathFinishedGame}` })
                    
                } 
            },
            getContent(){
                HTTP.get(Routes.popUpCompleted)
                .then(  response => {
                    if(response.data.err === 0){
                        this.popUpTitle = response.data.popUpCompleted.completed_text
                        this.popUpButton = response.data.popUpCompleted.completed_btn
                    }
                })
                .catch( error => {
                    console.log(error);
                });
            }
        },
        mounted(){
            this.getContent();
        }
    }
</script>