<template>
  <div style="width: 100%">
    <!-- новый слайдер с тремя продуктами -->
    <div v-if="!isPromo" class="block">
      <div class="mySwiper" ref="testSlider">
        <div
          v-for="(slideContent, index) in slides"
          :key="index"
          :class="'slideTest' + (index + 1) + ' ' + 'slideTest'"
        >
          <img :src="slideContent" alt="" @mouseover="index === 2 ? mouseoverMarsh = true : mouseoverMarsh = false" @mouseout="index === 2 ? mouseoverMarsh = false : mouseoverMarsh = false" />
          <div
            class="slider-prod__tag"
            :class="'slider-prod__tag_' + (index + 1)"
            v-html="slidesName[index]"
            v-if="slidesName[index]"
          />
        </div>
      </div>
      <div class="home-bg_img" :class="{opacity: mouseoverMarsh}" :key="mouseoverMarsh">
            <img class="bg_img bg_img_bottom" src="../assets/marsh1.png" alt="">
            <img class="bg_img bg_img_left" src="../assets/marsh2.png">
            <img class="bg_img bg_img_top_big" src="../assets/marsh3.png">
            <img class="bg_img bg_img_top_small" src="../assets/marsh2.png">
            <img class="bg_img bg_img_bottom2" src="../assets/marsh11.png" alt="">
            <img class="bg_img bg_img_left2" src="../assets/marsh2.png">
            <img class="bg_img bg_img_top_big2" src="../assets/marsh32.png">
            <img class="bg_img bg_img_top_small2" src="../assets/marsh41.png">
        </div>
      <!-- <div
                class="prod-slider__button">
                <button @click="clickNext" class="slider-button" id="slider-button-prev" slot="button-prev"><i class="far fa-angle-left"></i></button>
                <button @click="clickPrev" class="slider-button" id="slider-button-next" slot="button-next"><i class="far fa-angle-right"></i></button>
            </div> -->
    </div>
    <!-- <div class="btn-bottom">
            <span class="footer__buttonScroll-text">{{home.btn_next}}</span>
            <router-link
            v-on:click.native="goToPage()"
            tag="button"
            :to="`/${this.langContent}/game`"
            class="button button-down button_white"
          >
            <i class="far fa-angle-down"></i>
          </router-link>
        </div> -->
    <!-- пока убираем блок с кнопками и текстом, т.к. появился третий продукт-->
    <div v-if="!isPromo && false" class="block">
      <div class="title-block">
        <!--                <img :src="homeLogo" alt="" class="logo-home">-->
        <h1 class="title" v-html="home.title"></h1>
        <p class="description" v-html="home.description"></p>
        <div class="title-block__button">
          <router-link
            v-on:click.native="goToPage()"
            tag="button"
            :to="`/${this.langContent}/game`"
            class="button button-next-slide button_brown"
            v-html="home.btn_next"
          ></router-link>
          <router-link
            v-on:click.native="goToPage()"
            tag="button"
            :to="`/${this.langContent}/game`"
            class="button button-down button_white"
          >
            <i class="far fa-angle-down"></i>
          </router-link>
        </div>
        <!-- <transition name="fade-footer"> -->
        <div class="buy-wrapper" v-if="shops">
          <div class="shop-wrapper">
            <a v-if="shops && shops.ozon" :href="shops.ozon" target="_black">
              <img src="../assets/buy/ozon.png" alt="ozon" />
            </a>
            <a
              v-if="shops && shops.ytkonos"
              :href="shops.ytkonos"
              target="_black"
            >
              <img src="../assets/buy/utkonos.png" alt="utkonos" />
            </a>
            <a v-if="shops && shops.bery" :href="shops.bery" target="_black">
              <img src="../assets/buy/beru.png" alt="beru" />
            </a>
            <a v-if="shops && shops.wb" :href="shops.wb" target="_black">
              <img src="../assets/buy/wildberries.png" alt="wildberries" />
            </a>
          </div>
        </div>
        <!-- </transition> -->
      </div>
      <div class="slider-prod" v-if="home.prod1_name">
        <div
          class="slider-prod__tag slider-prod__tag_brown"
          v-html="home.prod1_name"
        />
        <div
          class="slider-prod__tag slider-prod__tag_white"
          v-html="home.prod2_name"
          v-if="home.prod2_name"
        />
        <div
          class="slide slide_active"
          :class="{ activePrev: active, notActivePrev: notActive }"
        >
          <img
            :src="prodYellow"
            class="prod-img"
            alt=""
          />
        </div>
        <div
          class="slide slide_next"
          :class="{ activeNext: active, notActiveNext: notActive }"
          v-if="home.prod2_name"
        >
          <img :src="prodPink" alt="" />
        </div>
      </div>
      <!-- <div class="bg-wave-cocao">
            <img src="../assets/wave_cocao.png" alt="">
        </div> -->
    </div>
    <div v-else-if="isPromo " class="promo-page content__main" v-cloak>
      <!-- <div class="bg-one" :class="{active:isPromoTrans}"> -->
      <!-- <div class="blob-main"></div> -->
      <!-- <img class="cosmo-palsnet" src="../assets/planet-1.png" alt=""> -->
      <!-- <img class="cosmo-ball" src="../assets/ball.png" alt=""> -->
      <!-- <div class="promo-right-box">
                    <div class="space-logo-wr">
                        <img src="../assets/space-logo.png" alt="">
                        <p class="promo-pretitle" v-html="promo.pretitle"></p>
                    </div>
                    <div class="xbox-wr">
                        <img class="xbox" src="../assets/xbox.png" alt="">
                        <div class="star star_1"></div>
                        <div class="star star_2"></div>
                        <div class="star star_3"></div>
                        <div class="star star_4"></div>
                        <div class="star star_5"></div>
                    </div>

                </div> -->
      <!-- </div> -->
      <div class="promo-bg_img">
        <img
          class="bg_img-promo bg_img_cloud"
          src="../assets/cloud.png"
          alt=""
        />
        <img
          class="bg_img-promo bg_img_cloud_small"
          src="../assets/cloud.png"
          alt=""
        />
        <img
          class="bg_img-promo bg_img_cloud_2"
          src="../assets/cloud.png"
          alt=""
        />
        <img
          class="bg_img-promo bg_img_cloud_small_2"
          src="../assets/cloud.png"
          alt=""
        />
        <img
          :class="{ 'show-gift': isShowGift }"
          class="bg_img-promo bg_img_gift_big"
          src="../assets/big_gift.png"
          alt=""
        />
        <img
          :class="{ 'show-gift': isShowGift }"
          class="bg_img-promo bg_img_gift_small"
          src="../assets/small_gift.png"
          alt=""
        />
      </div>
      <div class="promo-left-box">
        <div class="promo-img-box">
          <div class="front" v-if="promo.image">
            <img :src="promo.image" alt="" />
          </div>
        </div>
        <!-- <div class="promo-title-box">
                    <h1 class="promo-title" v-html="promo.title"></h1>
                </div> -->
        <div>
          <a
            href="http://promo-macchoco.ru"
            target="_blank"
            class="promo-button-wr"
          >
            <button
              class="button button_brown"
              v-html="promo.btn_text"
            ></button>
          </a>
        </div>
        <div class="buy-wrapper">
          <div class="shop-wrapper">
            <a v-show="shops && shops.ozon" :href="shops.ozon" target="_black">
              <img src="../assets/buy/ozon.png" alt="ozon" />
            </a>
            <a
              v-show="shops && shops.ytkonos"
              :href="shops.ytkonos"
              target="_black"
            >
              <img src="../assets/buy/utkonos.png" alt="utkonos" />
            </a>
            <a v-show="shops && shops.bery" :href="shops.bery" target="_black">
              <img src="../assets/buy/beru.png" alt="beru" />
            </a>
            <a v-show="shops && shops.wb" :href="shops.wb" target="_black">
              <img src="../assets/buy/wildberries.png" alt="wildberries" />
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="promo-right-box">
            <div class="space-logo-wr">
                <img src="../assets/space-logo.png" alt="">
                <p class="promo-pretitle" v-html="promo.pretitle"></p>
            </div>
            <div class="xbox-wr">
                <img class="xbox" src="../assets/xbox.png" alt="">
                <img class="stars" src="../assets/stars.png" alt="">
            </div>

		</div> -->

      <div class="promo-button-wr-game">
        <router-link
          v-on:click.native="goToPage()"
          tag="button"
          :to="`/${this.langContent}/game`"
          class="button button-next-slide button_brown"
          v-html="promo.btn_next_text"
        ></router-link>
        <router-link
          v-on:click.native="goToPage()"
          tag="button"
          :to="`/${this.langContent}/game`"
          class="button button-down button_white"
        >
          <i class="far fa-angle-down"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@color-yellow: #fece00;
@color-brown: #92411d;
@color-dark-brown: #521700;
.btn-bottom {
  position: fixed;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1300px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}
.mySwiper {
  height: 600px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .slider-prod__tag {
    white-space: pre;
    height: max-content;
  }

  .slider-prod__tag_1 {
    // bottom: 29px;
    right: -75px;
    color: @color-yellow;
    transform: rotate(15deg) scale(1.15);
    text-shadow: none;
    top: -50px;
    &::after {
      content: url(../assets/arrow_02.svg);
      position: absolute;
      right: -40px;
      top: 30px;
      transform: rotate(165deg) scale(-1, 1);
    }
  }
  .slider-prod__tag_3,
  .slider-prod__tag_2 {
    color: @color-brown;
    &::before {
      content: url(../assets/arrow_01.svg);
      position: absolute;
      left: -40px;
      bottom: -71px;
      transform: rotate(19deg);
      filter: drop-shadow(1px 1px 4px rgba(255, 196, 0, 1));
    }
  }
  .slider-prod__tag_2 {
    bottom: 100px;
    left: -115px;
    transform: rotate(336deg) scale(1.15);
    &::before {
      transform: rotate(215deg) scale(-1, 1);
      bottom: 50px;
      left: 60px;
    }
  }
  .slider-prod__tag_3 {
    top: -125px;
    left: 100px;
    transform: rotate(360deg) scale(1.15);
  }
}

.slideTest {
  position: absolute;
  transform: translateX(0) scale(1);
  transition: 0.5s;
  z-index: 0;
  &:hover {
    transform: scale(1);
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-image: url(../assets/shadow1.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.slideTest3 {
  transform: translateZ(250px);
  &::after {
    // background-image: url(../assets/shadow_01.png);
    bottom: 20px;
    left: 0;
    // opacity: 0.4;
    // filter: brightness(0.3);
    // height: 198px;
  }
//   &::before {
//     content: "";
//     position: absolute;
//     z-index: -1;
//     background-image: url(../assets/shadow_02.png);
//     bottom: -8px;
//     right: -75%;
//     opacity: 0.4;
//     filter: brightness(0.3);
//     height: 250px;
//     width: 100%;

//   }
  &:hover {
    transform: scale(1.1) translate(0);
    z-index: 1;
  }
}
.slideTest1 {
  transform: translate(75%, 0%) scale(0.85);
  &::after {
    background-image: url(../assets/shadow_02.png);
    bottom: -8px;
    right: -75%;
    opacity: 0.4;
    filter: brightness(0.3);
    height: 250px;
  }
  &:hover {
    transform: scale(1) translate(80%, 0%);
    z-index: 1;
  }
}
.slideTest2 {
  transform: translate(-75%, 0%) scale(0.85);
  &::after {
    background-image: url(../assets/shadow_01.png);
    bottom: 7px;
    left: -70%;
    opacity: 0.4;
    filter: brightness(0.3);
    height: 198px;
  }
  &:hover {
    transform: scale(1) translate(-80%, 0%);
    z-index: 1;
  }
}
.prod-slider__button {
  z-index: 1;
}

@keyframes prev {
  0% {
    transform: translateX(100%) scale(0.5);
    filter: blur(2px);
    visibility: hidden;
  }
  50% {
    transform: translateX(100%) scale(0.7);
    filter: blur(1px);
    visibility: visible;
  }
  100% {
    transform: translateX(0) scale(1);
    filter: blur(0px);
    visibility: visible;
  }
}
@keyframes next {
  0% {
    transform: translateX(0) scale(1);
    filter: blur(0px);
    visibility: visible;
  }
  50% {
    transform: translateX(-100%) scale(0.7);
    filter: blur(1px);
    visibility: visible;
  }
  100% {
    transform: translateX(-100%) scale(0.5);
    filter: blur(2px);
    visibility: hidden;
  }
}
</style>

<script>
import axios from "axios";
import VueAxios from "vue-axios";

import { HTTP, Routes } from "../API";

export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    blockSliderHome: {
      type: Boolean,
      required: false,
    },
    langContent: {
      type: String,
      default: "ru",
    },
  },
  data() {
    return {
      srollPageVal: "slide-fadeTop",
      removeScrollButton: true,
      x: 0,
      y: 0,
      mousemoveAcitve: false,
      mousemovePromo: true,
      active: false,
      flag: false,
      notActive: false,
      prodYellow: null,
      homeLogo: null,
      prodPink: null,
      home: [],
      linkId: null,
      isPromo: "",
      promo: [],
      isPromoTrans: false,
      waveCocao: false,
      shops: {},
      isShowGift: false,
      // imageTest: require ('../assets/main.png')
      slides: [],
      slidesName: [],
      mouseoverMarsh: false,
    };
  },
  methods: {
    clickNext() {},
    clickPrev() {},
    goToPage() {
      this.$emit("scrollPage", this.srollPageVal);
    },
    mousemove: function (e) {
      if (!this.isPromo && this.home.prod2_name) {
        // if(true){

        this.x = e.pageX;
        this.y = e.pageY;
        /**
         * this.blockSliderHome (Заблоировать измеение фона и слайдер продукта)
         * this.blockSliderHome из компонента header
         */
        if (this.blockSliderHome !== true) {
          if (this.x >= 1000) {
            // желтая
            this.mousemoveAcitve = true;
            this.$emit("mousemove", this.mousemoveAcitve);
            // Анимация для пачек
            this.active = true;
            this.flag = true;
          } else if (this.y <= 678) {
            // Розовая
            this.mousemoveAcitve = false;
            this.$emit("mousemove", this.mousemoveAcitve);
            // Анимация для пачек
            this.active = false;
            if (this.flag === true) {
              this.notActive = true;
              // Отключить обратную анимацию
              setTimeout(() => {
                this.notActive = false;
                this.flag = false;
              }, 900);
            }
          }
        }
      }
    },
    getContent() {
      HTTP.get(Routes.home)
        .then((response) => {
          if (response.data.err === 0) {
            if (response.data.promo === 0) {
              this.isPromo = false;
              //   console.log('response.data: ', response.data);
              this.home = response.data.main;
              // console.log('this.home: ', this.home.shops.ozon);
              this.prodYellow = response.data.main.prod1_image;
              this.prodPink = response.data.main.prod2_image;
              this.prodYellow = response.data.main.prod3_image;
              this.homeLogo = response.data.main.image;
              this.shops = response.data.main.shops;
              let arrSlides = [];
              arrSlides.push(
                response.data.main.prod1_image,
                response.data.main.prod2_image,
                response.data.main.prod3_image
              );
              this.slides.push(...arrSlides);
              this.slidesName = [
                response.data.main.prod1_name,
                response.data.main.prod2_name,
                response.data.main.prod3_name,
              ];
            } else {
              this.isPromo = true;
              this.promo = response.data.main_promo;
              this.shops = response.data.main_promo.shops;
              // console.log('this.promo: ', this.promo);
              this.mousemovePromo = false;
              this.$emit("removeWave", this.mousemovePromo);
              setTimeout(() => (this.isShowGift = true), 10);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    window.addEventListener("mousemove", this.mousemove);

    // console.log(this.isPromoTrans);
  },
  updated() {
    this.isPromoTrans = true;
  },
  created() {
    this.getContent();
  },
  beforeCreate() {
    this.removeScrollButton = false;
    this.$emit("removeButtonScrollFooter", this.removeScrollButton);
    this.waveCocao = true;
    this.$emit("addWaveCocaoFooter", this.waveCocao);
  },
  destroyed() {
    this.isPromoTrans = false;
    this.mousemoveAcitve = false;
    this.$emit("mousemove", this.mousemoveAcitve);
    window.removeEventListener("mousemove", this.mousemove);

    this.removeScrollButton = true;
    this.waveCocao = false;
    this.$emit("addWaveCocaoFooter", this.waveCocao);

    setTimeout(() => {
      this.$emit("removeButtonScrollFooter", this.removeScrollButton);
    }, 500);
    this.mousemovePromo = false;
    this.$emit("removeWave", this.mousemovePromo);
  },
};
</script>
<style lang="less">
.promo-page {
  display: flex;
  justify-content: space-between;
  /* padding: 50px 200px 0 135px; */
  align-items: center;
  position: relative;
  width: 100%;
}
.promo-page .button_brown {
  margin: 0;
}

.promo-page.content__main {
  justify-content: flex-start;
  height: 90%;
  height: 100vh;
  width: 100vw;
}

.bg-wave-cocao {
  position: relative;
  animation: animationText 1.2s forwards, animationOpacity 3s ease;
}

.bg-wave-cocao img {
  position: absolute;
  bottom: -45px;
  left: 0;
}

.promo-left-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 65px; */
  /* padding-right: 180px; */
  width: 65%;
  transform: translateY(-30px);
  .buy-wrapper {
    padding-top: 25px;
  }
}

.promo-button-wr .button_brown {
  font-size: 18px;
  line-height: 22px;
  padding: 15px 30px;
  letter-spacing: 0.01em;
}

.promo-button-wr .button_brown span {
  text-decoration: underline;
}

.promo-button-wr-game {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 35px;
  // bottom: -120px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}

.promo-button-wr-game .button-next-slide {
  font-size: 18px;
  margin-right: 13px;
}

.promo-button-wr-game .button-down {
  width: 50px;
  height: 50px;
  padding: 15px;
}

.promo-pretitle {
  font-family: Stolzl;
  font-size: 21px;
  line-height: 126%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.promo-title-box {
  text-align: center;
}

.promo-title {
  margin: 25px 0;
  font-family: Stolzl Bold;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #1d448c;
  width: 350px;
}

.promo-img-box {
  position: relative;
}
.front {
  width: 100%;
  margin: 0 auto;
}
// .promo-img-box .front::after {
//     content: '';
//     position: absolute;
//     z-index: -1;
//     left: 200px;
//     bottom: -13px;
//     opacity: 0.5;
//     filter: brightness(0.5);
//     height: 365px;
//     width: 425px;
//     background-image: url(../assets/promo-shadow.png);
//     background-size: contain;
//     background-repeat: no-repeat;
// }

.front img {
  max-width: 100%;
  height: auto;
}

.promo-right-box {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-70%, 0%);
}

.cosmo-palsnet {
  position: absolute;
  top: 5%;
  left: 15%;
}

.cosmo-ball {
  position: absolute;
  right: 10%;
  top: 25%;
}

.xbox-wr {
  position: relative;
}

.space-logo-wr {
  position: relative;
}

.bg-one {
  position: fixed;
  background-repeat: no-repeat;
  top: 0;
  background-image: url(../assets/start-bg-lg.png);
  background-position: center top;
  background-size: 100% auto;
  right: -50px;
  width: 1006px;
  min-height: 685px;
  animation: opacityBgCosmos 2s;
  z-index: -1;
}

/* .bg-one.active {
    opacity: 1;
} */
.blob-main {
  /* width: 1000px;
    height: 1230px; */
  /* background-image: url(../assets/start-bg-lg.png); */
  /* background: radial-gradient(65.25% 70.88% at 65.08% 37.48%, #F09711 0%, #FF9534 100%), radial-gradient(125.93% 121.8% at 108.15% 30.81%, #ED8419 0%, #E87312 100%); */
  /* border-radius: 51% 49% 29% 71% / 56% 67% 33% 44%; */
  /* box-shadow: 0px 4px 47px rgba(253, 150, 46, 0.8); */
  /* animation: wobble 40s linear infinite; */
  /* transform: translate(30%, -33%); */
  /* background-repeat: no-repeat;
    background-size: cover;
    background-position: 93% 100%; */
  /* width: 860px;
    height: 1000px; */
}

@keyframes wobble {
  0% {
    border-radius: 51% 49% 29% 71% / 56% 67% 33% 44%;
  }

  25% {
    border-radius: 51% 49% 71% 29% / 69% 48% 52% 31%;
  }

  50% {
    border-radius: 32% 68% 44% 56% / 33% 57% 43% 67%;
  }

  75% {
    border-radius: 68% 32% 57% 43% / 57% 28% 72% 43%;
  }
}

.buy-wrapper {
  padding-top: 45px;
  position: relative;
  z-index: 2;
}

.shop-wrapper {
  display: flex;
  align-items: center;
}

.shop-wrapper a {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.shop-wrapper a:nth-child(2) {
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 39px;
}

.star {
  position: absolute;
  display: block;
  background-image: url(../assets/star.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  animation-name: stars;
  animation-iteration-count: infinite;
}

.star_1 {
  top: 1px;
  left: -25px;
  width: 70px;
  height: 70px;
  transform: rotate(123deg);
  animation-duration: 4s;
}

.star_2 {
  top: -10px;
  left: 210px;
  width: 100px;
  height: 100px;
  animation-duration: 5s;
  transform: rotate(72deg);
}

.star_3 {
  top: 90px;
  left: 332px;
  width: 56px;
  height: 56px;
  animation-duration: 3.2s;
  transform: rotate(72deg);
}

.star_4 {
  top: 178px;
  left: 52px;
  width: 74px;
  height: 74px;
  animation-duration: 4.8s;
  transform: rotate(88deg);
}

.star_5 {
  top: 145px;
  left: 346px;
  width: 74px;
  height: 74px;
  animation-duration: 2.7s;
  transform: rotate(104deg);
}

@keyframes stars {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes opacityBgCosmos {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.promo-bg_img {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  .bg_img_cloud_small {
    top: 10%;
    width: 75px;
    right: 45%;
    animation: cloud_sm 1.2s forwards, animationOpacity 2s ease;
  }
  .bg_img_cloud {
    top: 45%;
    left: -15px;
    animation: cloud 2s ease forwards, animationOpacity 3s ease;
  }
  .bg_img_cloud_2 {
    right: 31%;
    top: 22%;
    animation: cloud_sm 1.5s ease forwards, animationOpacity 2s ease;
  }
  .bg_img_cloud_small_2 {
    width: 75px;
    left: 11%;
    bottom: 33%;
    animation: cloud 0.5s forwards, animationOpacity 2s ease;
  }

  .bg_img_gift_small:hover,
  .bg_img_gift_big:hover {
    animation: swing 0.6s ease;
    animation-iteration-count: 1;
  }
}
.bg_img-promo {
  position: absolute;
  // animation:  animationOpacity 3s ease;
  animation-iteration-count: 1;
  transition: all 0.3s ease, opacity 2s ease, bottom 1s ease, right 1s ease;
  transform: rotate(-135deg);
  bottom: -300px;
  opacity: 0;
  right: 10%;
}
.show-gift {
  opacity: 1;
  transform: rotate(0);
}
.show-gift.bg_img_gift_big {
  bottom: 25%;
  right: 5%;
}
.show-gift.bg_img_gift_small {
  bottom: 14%;
  right: 20%;
}
@keyframes cloud {
  0% {
    opacity: 0.5;
    transform: translateX(500px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes cloud_sm {
  0% {
    opacity: 0.5;
    transform: translateX(-500px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes swing {
  15% {
    -webkit-transform: translateX(9px);
    transform: translateX(9px);
  }
  30% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px) rotateZ(15deg);
  }
  40% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  65% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 1360px) {
  .bg-one {
    width: 900px;
  }

  .promo-right-box {
    top: 50px;
    left: 53%;
  }
}

@media (max-width: 1200px) {
  .promo-page.content__main {
    // width: 1130px;
  }

  .front {
    width: 80%;
  }

  .promo-img-box .front::after {
    left: 160px;
  }

  .promo-title {
    margin: 15px 0;
    font-size: 18px;
    line-height: 22px;
    width: 300px;
  }

  .promo-button-wr .button_brown {
    font-size: 14px;
    padding: 10px 24px;
  }

  .buy-wrapper {
    padding-top: 25px;
  }

  .promo-button-wr-game {
    // bottom: -90px;
  }
}
@media (min-height: 1025px) {
  .front {
    width: 80%;
  }
}

@media (max-height: 800px) {
  .front {
    width: 300px;
  }

  .promo-img-box .front::after {
    left: 170px;
  }

  .promo-title {
    margin: 15px 0;
    font-size: 18px;
    line-height: 20px;
    width: 300px;
    text-shadow: 1px 1px 5px white;
    position: relative;
  }

  .buy-wrapper {
    padding-top: 15px;
  }

  .promo-button-wr-game {
    // bottom: -80px;
  }

  .xbox {
    width: 80%;
  }
}
</style>
