<template>
    <div class="win">
        <div class="win__box">
            <img :src="winLogo" class="win__logo" alt="">
            <h2 class="win-box__percent">{{ number }}%</h2>
            <p class="win-box__textWin" :class="{textWin_active:textWinShow}" v-html="text"></p>
            <div class="win__link">
                <p class="win__link-title" v-html="socNetworkText"></p>
                <ul class="link-list">
                    <li v-for="item in socNetwork" :key="item.class" class="link-list__item">
                        <div class="link" @click="sharerResults(item.name)">
                            <span class="link-list__icon"><i :class="item.class"></i></span>
                        </div>
                    </li>
                </ul>
            </div>
            <button class="button button_brown win__button" @click="anew" v-html="gameButton"></button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import { HTTP, Routes, testLoc, locOrig } from '../API'
    export default {
        props:{
            langContent:{type: String, default: 'ru'},
        },
        data(){
            return{
                number: 0,
                idGame: '',
                textWinShow: false,
                blockQuestButton: false,
                text: null,
                gameButton: null,
                socNetworkText: null,
                socNetwork:[],
                winLogo: null,
                win0: null,
                win33: null,
                win66: null,
                win100: null,
                reset: '',
                // shareResults: null
            }
        },
        methods:{
            // Шаринг соцсетей
            sharerResults(socNetwork){
                if(isNaN(localStorage.percent) === true){
                    localStorage.percent = 0
                }

                // let shareResultsInfo = this.shareResults.findIndex( item =>{
                //     return item.procent == Number(localStorage.percent)
                // })
                // console.log(this.shareResults[shareResultsInfo].image)
                // // console.log(shareResultsInfo.image)
                let url
                switch (socNetwork) {
                    case 'twitter':
                        url  = 'http://twitter.com/share?'
                        url += '&url=' + encodeURIComponent(`${locOrig}/${this.langContent}/result/`+localStorage.percent+'/')
                       break
                    case 'vk':
                        url  = 'http://vkontakte.ru/share.php?'
                        url += 'url=' + encodeURIComponent(`${locOrig}/${this.langContent}/result/`+  localStorage.percent +'')
                        break
                    case 'odnoklassniki':
                        url  = 'https://connect.ok.ru/offer?'
                        url += 'url=' + encodeURIComponent(`${locOrig}/${this.langContent}/result/`+  localStorage.percent +'')
                        break
                    case 'facebook':
                        url = 'http://www.facebook.com/sharer.php?'
                        url += 'u=' + encodeURIComponent(`${locOrig}/${this.langContent}/result/`+  localStorage.percent +'')
                        break
                }  
                window.open(url,'sharer','toolbar=0,status=0,width=626,height=436');
            },
            // Начать заново
            anew(){
                this.$router.push({ path: `/${this.langContent}/game/distinction` })
                localStorage.clear()
                const search = '#NUMBER#';
                let replaceWith = '1'
                this.reset = this.reset.split(search).join(replaceWith)
            },
            getContent(){
                HTTP.get(Routes.result_quests)
                .then(  response => {
                    if(response.data.err === 0){
                        // Заново
                        this.gameButton = response.data.result_quests.btn_anew
                        // Поделиться
                        this.socNetworkText = response.data.result_quests.soc_network_text
                        // Социальные сети
                        this.socNetwork = response.data.result_quests.soc_network
                        // Логотип
                        // **** Финальный текст ****
                        this.winLogo = response.data.result_quests.image
                        this.win0  = response.data.result_quests.text[0]
                        this.win33 = response.data.result_quests.text[1]
                        this.win66 = response.data.result_quests.text[2]
                        this.win100 = response.data.result_quests.text[3]
                        setTimeout(() => {
                            if (Number(localStorage.percent) == 33 || Number(localStorage.percent) == 34) {
                                this.text = this.win33 
                            } else if((Number(localStorage.percent) == 66) || (Number(localStorage.percent) == 67)){
                                this.text = this.win66 
                            } else if((Number(localStorage.percent) == 100)){
                                this.text = this.win100
                            } else {
                                this.text = this.win0
                            }
                        }, 0)
                            // **** Финальный текст END****
                    }
                })
            .catch( error => {
                console.log(error);
            });
            HTTP.get(Routes.games_options)
            .then(  response => {
                this.reset = response.data.games_options.quest
            })
            .catch( error => {
                console.log(error);
            });
            }

        },
        watch:{
            // langContent: function(newValue, oldValue){
            //     this.getContent();
            // }
        },
        mounted(){
            this.getContent();
            // console.log(Number(localStorage.percent))
            if(isNaN(localStorage.percent) === true){
                localStorage.percent = 0
            }

            if(Number(localStorage.percent) !== 0){
                let interval = setInterval(() => {
                    this.number ++
                    if (this.number === Number(localStorage.percent)) {
                        this.textWinShow = true
                        clearInterval(interval)
                        interval = null
                    }
                }, 30);
            }else{
                this.textWinShow = true
                this.number = 0
            }
            // Записать id страницы
            this.idGame = this.$route.meta.id
            this.$emit('blockQuest',this.blockQuestButton)

            // axios
            //     .get('https://macchoco.ru/includes/api/getData.php?type_data=result_quests')
            //     .then(  response => {
            //         if(response.data.err === 0){
            //             // Заново
            //             this.gameButton = response.data.result_quests.btn_anew
            //             // Поделиться
            //             this.socNetworkText = response.data.result_quests.soc_network_text
            //             // Социальные сети
            //             this.socNetwork = response.data.result_quests.soc_network
            //             // Логотип
            //             // **** Финальный текст ****
            //             this.winLogo = response.data.result_quests.image
            //             this.win0  = response.data.result_quests.text[0]
            //             this.win33 = response.data.result_quests.text[1]
            //             this.win66 = response.data.result_quests.text[2]
            //             this.win100 = response.data.result_quests.text[3]
            //             setTimeout(() => {
            //                 if (Number(localStorage.percent) == 33 || Number(localStorage.percent) == 34) {
            //                     this.text = this.win33 
            //                 } else if((Number(localStorage.percent) == 66) || (Number(localStorage.percent) == 67)){
            //                     this.text = this.win66 
            //                 } else if((Number(localStorage.percent) == 100)){
            //                     this.text = this.win100
            //                 } else {
            //                     this.text = this.win0
            //                 }
            //             }, 0)
            //                 // **** Финальный текст END****
            //         }
            //     })
            // .catch( error => {
            //     console.log(error);
            // });
            // axios.get('https://macchoco.ru/includes/api/getData.php?type_data=games_options')
            // .then(  response => {
            //     this.reset = response.data.games_options.quest
            // })
            // .catch( error => {
            //     console.log(error);
            // });
            // axios.get('http://macchoco.ru/includes/api/getData.php?type_data=share_results')
            // .then(  response => {
            //     if(response.data.err === 0){
            //         this.shareResults = response.data.share_results
            //         console.log(this.shareResults)
            //     }
            // })
            // .catch( error => {
            //     console.log(error);
            // });
        },
        watch:{
            idGame(newName) {
                localStorage.idGame = newName
            },
        },
        beforeDestroy(){
            this.blockQuestButton = true
            this.$emit('blockQuest',this.blockQuestButton)
        }
    }
</script>
