<template>
  <div id="app" @wheel="onWheel">
    <div class="content">
      <appHeader 
        @changeBackground="changeBackground"
        @scrollPage="scrollPage"
        :langContent="lang"
      />
      <div class="background" >
          <div  class="background__yellow" :class="{background__box44:active,background__box90:openMenu, background__box100:activePromo}">
          </div>
          <div class="background__brown" :class="{background__box56:active,background__box10:openMenu, background__box0:activePromo}">
            <Wave />
          </div>
      </div>
      <div class="content__main">
          <transition :name="slidePage" mode="out-in">
            <router-view 
              :blockSliderHome="blockSliderHome" 
              :langContent="lang"
              @mousemove="mousemove" 
              @removeWave="removeWave"
              @addLogoFooter="addLogoFooter" 
              @addQuestButtonFooter="addQuestButtonFooter"  
              @addTextFoundFooter="addTextFoundFooter" 
              @numberDifferences="numberDifferences"
              @noScroll="noScroll"
              @removeButtonScrollFooter="removeButtonScrollFooter"
              @scrollPage="scrollPage"
              @buttonNextScroll="buttonNextScroll"
              @numberQuestApp="numberQuestApp"
              @addWaveCocaoFooter="addWaveCocaoFooter"
            />
          </transition>
      </div>
      <appFooter 
        :langContent="lang"
        :addContentFooter="addContentFooter" 
        :addButtonQuest="addButtonQuest" 
        :showTextFoundFooter="showTextFoundFooter" 
        :showNumberDifferences="showNumberDifferences"
        :removeButtonScroll="removeButtonScroll"
        :textButtonNextScroll="textButtonNextScroll"
        @scrollPage="scrollPage"
        :numberQuestAppText="numberQuestAppText"
        :addWaveCocao='addWaveCocao'
      />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Header from './components/Header'
  import Footer from './components/Footer'
  import Wave from '@/components/Wave'

  export default {
    props:{
      mousemoveAcitve:{ type: Boolean},
      showQuestButton:{type: Boolean, required: false},
      removeScrollButton:{type: Boolean,required: false},
    },
    data() {
      return {
        slidePage: '',
        active: false,
        scrollRoute: null,
        routesLength: this.$router.options.routes.length,
        addContentFooter: false,
        idPageFooter: 0,
        addButtonQuest: false,
        blockSliderHome: false,
        openMenu: false,
        showTextFoundFooter: false,
        showNumberDifferences: '0',
        noScrollPage: true,
        scroll: true,
        removeButtonScroll: false,
        deltaScroll: 0,
        deltaScrollButton: 0,
        textButtonNextScroll: null,
        numberQuestAppText: '',
        
        locationOrigin: document.location,
        lang: 'ru',
        activePromo: false,
        addWaveCocao: false
      }
    },
    name: 'App',
    components:{
        appHeader: Header,
        appFooter: Footer,
        Wave,
    },
    methods:{
      langContent(){
        const path = this.locationOrigin.pathname.split('/');
        this.lang = path[1];
      },
      numberQuestApp(numberQuest){
        this.numberQuestAppText = numberQuest
      },
      scrollPage(srollPageVal){
        this.slidePage = srollPageVal
      },
    
      mousemove(mousemoveAcitve){
          this.active = mousemoveAcitve
      },
      removeWave(mousemovePromo){
        this.activePromo = mousemovePromo
      },
      // Скрол через стрелки
      logKey(e) {
            if (e.keyCode == 40) {
                this.deltaScrollButton = 100
                this.onWheel()
            }
            // Скролл Вверх
            if(e.keyCode == 38){
                this.deltaScrollButton = -100
                this.onWheel()
            }
      },
      // Скролл
      onWheel(event) {
        if(this.scroll === true){
          if (this.noScrollPage !== false ) {
              this.scroll = false
              if(this.deltaScrollButton === 0 ){
                  // Если скролл через мыш
                  this.deltaScroll =  event.deltaY
              } else{
                  // Если скролл через кнопки
                  this.deltaScroll = this.deltaScrollButton
              }
              let routes = this.$router.options.routes
              // console.log('routes: ', routes);
              let idx = routes.findIndex(item => item.path === this.$route.path)
            if (this.$route.path === '/ru/') {
              idx = 0
            } else {
              if(idx === -1){
                idx = 1
            }
            }
            this.idPageFooter = idx
              this.$emit('idPage',this.idPageFooter)

              // Скролл вниз

              if (this.deltaScroll > 0 && idx < routes.length - 2)  {
                  this.$router.push(routes[idx+1]).catch(()=>{})
                  // Добавить логотип компании
                  this.addLogoFooter()
                  // Добавить кнопку для квеста
                  this.addQuestButtonFooter()
                  this.slidePage = 'slide-fadeTop'

              }
              // Скролл вверх
              if (this.deltaScroll < 0 && idx > 0) {
                  this.$router.push(routes[idx-1]).catch(()=>{})
                  this.slidePage = 'slide-fadeBottom'
              } 
              setTimeout(()=>{
                this.scroll = true
              },1000)
          }
        }

      },
      changeBackground(lockSlider){
        this.blockSliderHome = lockSlider
        if(lockSlider === true){
          this.openMenu = true
        } else {
          this.openMenu = false
        }
      },
      //добавить на главную брызги кокао
      addWaveCocaoFooter(show){
          this.addWaveCocao = show
      },
      // Добавить логотип компании
      addLogoFooter(show){
          this.addContentFooter = show
      },
      addTextFoundFooter(showTextFound){
        this.showTextFoundFooter = showTextFound
      },
      numberDifferences(numberDifferences){
        this.showNumberDifferences = numberDifferences
      },
      // Добавить кнопку для квеста
      addQuestButtonFooter(showQuestButton){
          this.addButtonQuest = showQuestButton
      },
      noScroll(blockScroll){
        this.noScrollPage = blockScroll
      },
      removeButtonScrollFooter(removeScrollButton){  
        this.removeButtonScroll = removeScrollButton
      },
      buttonNextScroll(nextButton){
        this.textButtonNextScroll = nextButton 
      }
    },
    beforeUpdate(){
      let routes = this.$router.options.routes
      let idx = routes.findIndex(item => item.path === this.$route.path)
      this.idPageFooter = idx
      this.$emit('idPage',this.idPageFooter)
    },
    mounted() {
      // Скрол через стрелки
      document.addEventListener('keydown', this.logKey);



      // axios.get('http://macchoco.loc.jet5.ru/includes/api/getData.php?type_data=lang')
      //       .then(  response => {
      //           this.lang = response.data.languages
      //           console.log('this.lang: ', this.lang);

      //       })
      //       .catch( error => {
      //       console.log(error);
      // });

      // console.log('this.routesLength: ', this.routesLength);
    },
    created() {
      this.langContent();
    }
  }
</script>

