import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Distinction from '../pages/Distinction.vue'
import Puzzel from '../pages/Puzzel.vue'
import Way from '../pages/Way.vue'
import Win from '../pages/Win.vue'





const Game = resolve =>{
  require.ensure(['../pages/Game.vue'],()=>{
    resolve(
      require('../pages/Game.vue')
    )
  })
}

const Cooking = resolve =>{
  require.ensure(['../pages/Cooking-method-new.vue'],()=>{
    resolve(
      require('../pages/Cooking-method-new.vue')
    )
  })
}

const News = resolve =>{
  require.ensure(['../pages/News.vue'],()=>{
    resolve(
      require('../pages/News.vue')
    )
  })
}

const Recipes = resolve =>{
  require.ensure(['../pages/Recipes.vue'],()=>{
    resolve(
      require('../pages/Recipes.vue')
    )
  })
}

const About = resolve =>{
  require.ensure(['../pages/About.vue'],()=>{
    resolve(
      require('../pages/About.vue')
    )
  })
}

const Where = resolve =>{
  require.ensure(['../pages/Where.vue'],()=>{
    resolve(
      require('../pages/Where.vue')
    )
  })
}

const Page404 = resolve =>{
  require.ensure(['../pages/Page404.vue'],()=>{
    resolve(
      require('../pages/Page404.vue')
    )
  })
}
let pathLang = 'ru';
function langContent(){
  const path = window.location.pathname.split('/');
  // console.log("langContent -> path", path)
  pathLang = path[1];
  // console.log('pathLang: ', pathLang);
};
langContent();

export default new VueRouter({
  mode: 'history',
  routes:[
    {
      path: '/' + pathLang,
      component: Home,
      name: 'Главная'
    },
    // {
    //   path: '/'+ pathLang + '/game',
    //   component: Game,
    //   redirect: '/'+ pathLang + '/game/distinction',
    //   name: 'Пройти квест',
    //   props: {
    //     langContent: pathLang
    //   },
    //   children: [
    //     {
    //       path: 'distinction',
    //       component: Distinction,
    //       meta:{
    //         id: 0
    //       }
    //     },
    //     {
    //       path:'puzzel',
    //       component: Puzzel,
    //       meta:{
    //         id: 1
    //       }
    //     },
    //     {
    //       path: 'way',
    //       component: Way,
    //       meta:{
    //         id: 2
    //       }
    //     },
    //     {
    //       path: 'win',
    //       component: Win,
    //       meta:{
    //         id: 3
    //       }
    //     }
    //   ]
    // },
    {
      path: '/'+ pathLang + '/cooking',
      component: Cooking,
      name: 'Продукт'
    },
    {
      path: '/'+ pathLang + '/recipes',
      component: Recipes,
      name: 'Вкусные рецепты с MacChoco',
      children:[
        {
          path: ':id'
        }
      ]
    },
    // {
    //   path: '/'+ pathLang + '/news',
    //   component: News,
    //   name: 'Новости',
    //   children:[
    //     {
    //       path: ':id'
    //     }
    //   ]
    // },
    {
      path: '/'+ pathLang + '/about',
      component: About,
      name: 'О компании',
      children:[
        {
          path: ':id'
        }
      ]
    },
    // {
    //   path: '/ru/where',
    //   component: Where,
    //   name: 'Где купить?'
    // },
    { 
      path: '*', 
      component: Page404
    },
  ],
})




