<template>
<div class="puzzel">
    <PopUpCompleted :popUpShow="popUpShow" :langContent="langContent" />
    <div class="preview-puzzel" :class="{previewPuzzel_active:puzzelActive}">
        <img src="../assets/games/preview.png" alt="">
    </div>
    <h2 class="game-content__title" v-html="gameTitle"></h2>
    <div class="game-box-border">
        <div  class="puzzel__box-cap" :class="{box__cap_active:bgActive}"></div>
        <ul class="puzzel__list">
            <li class="puzzel__list-item"  :style='{width:length+"px",height:length+"px","line-height":length+"px"}'
                    v-bind:class='{puzzle:puzzle,"puzzle-empty": !puzzle}'
                    v-for="(puzzle,index) in puzzles" @click="move(index)"  :key="index">
                    <img :src="puzzle.img">
                    {{ puzzle.id }}
            </li>
        </ul>
    </div>
  <button  class="button button_brown button_play" @click="mix">{{ gameButtonText }}</button>
</div>

</template>
<script>
import axios from 'axios'
import VueAxios from 'vue-axios'
import PopUpCompleted from '@/components/PopUpCompleted.vue'
import { HTTP, Routes } from '../API'
export default {
    components:{
        PopUpCompleted
    },
    props: {
        langContent: String
    },
    data(){
        return{
            num:4,
            idGame: '',
            percent:'',
            numberQuest: '',
            gameOverId1: false,
            text: 'Молодец!',
            gameButtonText: null,
            bgActive: false,
            popUpShow: false,
            puzzles:[],
            widthGame: 570,
            puzzelActive: false,

            gameTitle: null,
            gameButtonMove: null
        }
    },
    methods:{
        widthGameBox(){
            if(document.body.clientWidth <= 1400){
                this.widthGame = 362
            } else {
                this.widthGame = 570
            }
        },
        // Перемещать пазлы
        mix(){
            this.puzzelActive = true
            this.bgActive = true
            // Кнопка перемешать
            this.gameButtonText = this.gameButtonMove
            this.createPuzzle()
            this.puzzles.sort(()=>{
                    return Math.random()-0.5
            })
            this.puzzles.push('');
        },    
        render(){
            this.createPuzzle()
            this.puzzles.push('');
        },
        createPuzzle(){
            let obj = new Object();
            this.puzzles = []
            for (let i=1;i<this.num*this.num;i++) {
                let src = require('../assets/games/puzzel/'+i+'.png')
                obj.img = src
                obj.id = i
                this.puzzles.push(Object.assign({}, obj))
            }
        },
        move(index){
            /**
             * r - Вправо
             * l - Влево
             * d - Вверх
             * u - Вниз
             */
            let activePuzzel = document.getElementsByClassName('puzzle')
            for (let index = 0; index < activePuzzel.length; index++) {
                activePuzzel[index].classList.remove('puzzle-bottom')
                activePuzzel[index].classList.remove('puzzle-top')
                activePuzzel[index].classList.remove('puzzle-right')
                activePuzzel[index].classList.remove('puzzle-left')
            }
            let timePuzzel = 100
            let timeWin = 300

            const Vue = require('vue').default
            let cur=this.puzzles[index]
            let l=this.puzzles[index-1]
            let r=this.puzzles[index+1]
            let u=this.puzzles[index-this.num]
            let d=this.puzzles[index+this.num]
            
            if (l==='') {
                if (index !== 4 && index !== 8 && index !== 12) {
                    activePuzzel[index-1].classList.add('puzzle-left')
                    setTimeout(() => {
                        Vue.set(this.puzzles,index-1,cur)
                        Vue.set(this.puzzles,index,'')
                    },timePuzzel)
                }
            }else if (r===''){
                if (index !== 3 && index !== 7 && index !== 11) {
                    activePuzzel[index].classList.add('puzzle-right')
                    setTimeout(() => {
                        Vue.set(this.puzzles,index+1,cur);
                        Vue.set(this.puzzles,index,'')
                    },timePuzzel)
                }
            }else if (u===''){
                activePuzzel[index-1].classList.add('puzzle-bottom')
                setTimeout(() => {
                    Vue.set(this.puzzles,index-this.num,cur);
                    Vue.set(this.puzzles,index,'')
                },timePuzzel)
            }else if (d===''){
                activePuzzel[index].classList.add('puzzle-top')
                setTimeout(() => {
                    Vue.set(this.puzzles,index+this.num,cur);
                    Vue.set(this.puzzles,index,'')
                },timePuzzel)
            }

            setTimeout(() =>{
                this.isPass();
            },timeWin)
        },
        isPass(){
            let isPass = false             
            let flag = true
            this.puzzles.forEach(function(item,index){
                let id = item.id
                if (id === undefined) {
                    id = 16
                }
                if (flag) {
                    if(id === index + 1){
                        isPass = true
                    } else {
                        isPass = false
                        flag =  false
                    }
                }
            })
            if (isPass) {
                this.addLastPicture()
                let puzzleEmpty = document.getElementsByClassName('puzzle-empty')[0]
                if(puzzleEmpty.classList.contains('puzzle-empty_active')){
                    puzzleEmpty.classList.add('puzzle-empty_animation')
                }
                this.idGame = this.$route.meta.id
                this.percent = Number(localStorage.percent) + 33
                setTimeout(()=>{
                    this.popUpShow = true
                    this.gameOverId1 = true
                },900)
            }
        },
        addLastPicture(){
            let puzzleEmpty = document.getElementsByClassName('puzzle-empty')[0]
            puzzleEmpty.classList.add('puzzle-empty_active')     
        }
    },
    computed:{
        length(){
            return this.widthGame/this.num  
        }
    },
    created(){
        this.render()
    },
    watch:{
        idGame(newName) {
            localStorage.idGame = newName
        },
        percent(newName) {
            localStorage.percent = newName
        },
        gameOverId1(newName){
            localStorage.gameOverId1 = newName
        }
    },
    mounted(){
        // Была ли закончина игра 
        if(Boolean(localStorage.gameOverId1) === true) {
            // показать popUp
            this.popUpShow = true
            this.addLastPicture()
        }
        this.widthGameBox()

        HTTP.get(Routes.game_assemble_picture)
        .then(  response => {
            this.gameTitle = response.data.game_assemble_picture.title
            this.gameButtonText = response.data.game_assemble_picture.btn_start
            this.gameButtonMove = response.data.game_assemble_picture.btn_move
            })
        .catch( error => {
            console.log(error);
        });
        HTTP.get(Routes.games_options)
                .then(  response => {
                    this.numberQuest = response.data.games_options.quest
                    setTimeout(() =>{
                        const search = '#NUMBER#';
                        let replaceWith = '2'
                        this.numberQuest = this.numberQuest.split(search).join(replaceWith)
                        this.$emit('numberQuestGame',this.numberQuest)
                    }, 0)
            })
            .catch( error => {
            console.log(error);
        });

    }
}
</script>
