<template>
    <header>
        <router-link tag="a" :to="`/${this.langContent}/`" v-on:click.native="goToPageMain()">
          <div class="header__logo-wrapper">
            <img src="@/assets/logo/logoNew.svg" alt="" class="header__logo">
            <transition :name="showAnimation" mode="out-in">
                <div v-if="homeLogo">
                    <img
                        v-show="isShowLogo"
                        :src="homeLogo"
                        class="logo-home"
                    >
                </div>
            </transition>
          </div>
        </router-link>
        <div class="header__box">
            <!-- Язык -->
            <div class="header__menu-box-language">
                <button class="button button-language" 
                @click="buttonMenuActive = !buttonMenuActive,сloseMenu()" 
                :class="{activeMenu:buttonMenuActive}"><span>{{ language }}</span></button>
                <transition name="slide-fade-menu"> 
                    <ul class="header-menu-language " v-if="buttonMenuActive">
                        <li v-for="(item,index) in languages" :key="item.title" class="header-menu__item">
                            <button class="header-menu__link" @click="changeLanguage(index)">{{ item.title }}</button>
                        </li>
                    </ul>
                </transition>
            </div>
            <!-- Меню -->
            <div class="burger-box">
            <div class="header__menu-box"> 
                <!-- <router-link tag="button" to="/ru/where" v-on:click.native="сloseMenu(),goToPage(),buttonMenuActive = false" active-class="link-active" class="button button_white">
                    <span class="icon"><i class="far fa-shopping-basket"></i></span>Где купить
                </router-link> -->
                <transition name="slide-fade-menu">
                    <ul class="header-menu" v-show="buttonMenuOpen">
                        <li v-for="item in linkMenu" :key="item.titleLink" class="header-menu__item" @click="сloseMenu(),goToPage()">
                           <router-link exact-active-class="link-active" class="header-menu__link"  
                           tag="button" 
                           :to="item.link"
                           >{{item.name}}</router-link>
                        </li>
                    </ul>
                </transition>
            </div>
            <button class="button button_white button_menu" @click="changeBackground() ,buttonMenuOpen = !buttonMenuOpen, buttonMenuActive = false" :class="{opened:buttonMenuOpen}">
                <span class="button__top"></span>
                <span class="button__mid"></span>
                <span class="button__bot"></span>
            </button>
            </div>
        </div>
        <div class="backgroundMenu" @click="сloseMenu()" :class="{backgroundMenu_active:buttonMenuOpen}">
            <div class="backgroundMenu__brown" :class="{backgroundMenu__brown_active:buttonMenuOpen}">
                <Wave />
            </div>
        </div>
    </header>
</template>
<script>
    import {HTTP, Routes} from '../API';
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import Wave from '@/components/Wave'
    export default {
        props:{
            langContent:{type: String, default: 'ru'},
        },
        data(){
            return{
                srollPageVal: 'slide-fadeTop',
                buttonMenuOpen: false,
                buttonMenuActive: false,
                lockSlider: false,
                linkMenu:[],
                language: null,
                languages:[],
                homeLogo: null,
            }
        },
        components:{
            Wave
        },
        methods:{
            сloseMenu(){
                if(this.buttonMenuOpen === true){
                    this.buttonMenuOpen = false
                    this.changeBackground()
                }
            },
            changeBackground(){
                if(this.lockSlider === false){
                    this.lockSlider = true
                }else{
                    this.lockSlider = false
                }
                this.$emit('changeBackground',this.lockSlider)
            },
            // Изменение языка
            changeLanguage(index){
                               
                // запись в url
                const arUrl = window.location.pathname.split('/');
                let linkLang = this.languages[index].link.replace(/\W/g,'');
                arUrl[1] = linkLang;
                const newArUrl = arUrl.join('/');
                const newUrl = window.location.origin + newArUrl;
                history.pushState('', '', newUrl);
                location.reload(); //перезагрузка страницы
                

                this.buttonMenuActive = false
            },
            goToPage(){
                this.$emit('scrollPage',this.srollPageVal)
            },
            goToPageMain(){
                this.srollPageVal = 'slide-fadePage'
                this.$emit('scrollPage',this.srollPageVal)
            },
            getContent(){
                HTTP.get(Routes.lang)
                    .then(  response => { 
                        this.languages = response.data.languages
                        this.language = response.data.language
                })
                .catch( error => {
                    console.log(error);
                 }); 

                HTTP.get(Routes.menu)
                .then(  response => {
                    this.linkMenu = response.data.menu

                })
                .catch( error => {
                console.log(error);
                });

              HTTP.get(Routes.home)
                  .then(response => {

                    if (response.data.err === 0) {
                      if (response.data.promo === 0) {
                        this.homeLogo = response.data.main.image
                      }
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  });
            }
        },
      computed: {
        isShowLogo() {
         return this.$route.name === "Главная"
        },
        showAnimation() {
          return this.isShowLogo? 'slide-fadeBottom' : 'slide-fadeTop'
        }
      },
        created(){
            // Закрыть меню по esc
            document.onkeydown = evt => {
                evt = evt || window.event;
                if (evt.keyCode == 27 && this.buttonMenuOpen) {
                    this.buttonMenuOpen = false
                    this.changeBackground()
                }
                if(evt.keyCode == 27 && this.buttonMenuActive ){
                    this.buttonMenuActive = false
                }
            }
        },
        mounted(){
            this.getContent();
        },
        updated(){
            

        }
    }
</script>